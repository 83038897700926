body {
  margin: 0;
  padding: 0;
}

.nav-container {
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  padding: 20px;
  background-color:rgb(6 1 59);
  color: #fff;
}

.logo img {
  height: 50px;
  width: 120px;
  flex: 0.3;
}

.nav-element {
  justify-content: space-between;
  font-size: 20px;
  flex: 0.5;
  text-align: center;
  font-weight: 300;
}
.nav-element span {
  display: inline;

  margin-right: 30px;
}

.nav-element span:hover {
  color: black;
  text-decoration: underline;
}

.nav-element2 {
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  flex: 0.5;
  text-align: end;
  font-weight: 400;
}

.nav-element2 span {
  margin-right: 20px;
}
.nav-element2 span:hover {
  color: black;
  text-decoration: underline;
}


.main-Detail {
  background-color: rgba(131, 129, 127, 0.571);
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}
.anime-list-Detail {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  width: 30%;
  margin-right: 2px;
  margin-left: 5px;
}

.anime-recomendation:hover {
  transform: scale(0.9);

  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
}
.details {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 10px;
}
.synopsis {
  text-align: justify;
  font-size: 16px;
  font-weight: bold;

  margin-bottom: 10px;
  color: #888;
}
.synopsis2 {
  text-align: justify;
  font-size: 16px;
  font-weight: bold;

  margin-bottom: 10px;
  color: #888;
  display: none;
}
select {
  display: inline;

  margin-right: 30px;
  padding: 10px;
  font-size: 19px;
  text-decoration: none;
  background-color: rgb(0, 38, 134);
  color: #fff;
  cursor: pointer;
  border: none;
  appearance: none;
}
select:hover {
  display: inline;

  margin-right: 30px;
  padding: 10px;
  font-size: 19px;
  text-decoration: none;
  background-color: rgb(0, 38, 134);
  color: #000000;
}

/* Style the option elements */
option {
  width: 33.33%; /* Set the width to display three options per line */
  box-sizing: border-box;
  background-color: rgb(0, 38, 134);
  color: #fff;
}
.synopsiss {
  font-size: 16px;
  font-weight: bold;

  color: #4e4249;
}

.anime-title {
  font-size: 20px;
  font-weight: bold;

  margin-bottom: 10px;
}

.card-Detail {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 70px;
}
.card-Detail img {
  width: 100%;
  height: 500px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.title-rec {
  font-size: 16px;
  font-weight: bold;
}
.card-Detail .durration {
  font-size: 14px;
  color: #888;
}
.status {
  font-size: 14px;
  color: #888;
  flex: 1;
  text-align: end;
}
.title-recommendation {
  display: flex;
  align-items: center;
  text-align: center;
}
.anime-recomendation {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  width: 15%;
  margin-right: 2px;
  margin-bottom: 20px;
}
.card-rec {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll; /* Enables horizontal scrolling */
}

.name {
  display: flex;
  flex-wrap: nowrap;
}

::-webkit-scrollbar {
  color: #000000;
}

.image-rec {
  width: 200px;
  height: 330px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.rec {
  font-size: 16px;
  font-weight: bold;
}

@media screen and (max-width: 900px) {
  .nav-container {
    padding: 10px;
    display: flex;
  }
  .logo img {
    width: 62px;
    height: 30px;
    flex: 1;
  }
  .nav-element {
    margin-bottom: 10px;
    text-align: center;
    flex: 1;
  }
  .nav-element span {
    font-size: 100%;
    margin-right: 5px;
  }

  .nav-element2 {
    display: none;
  }

  .card-Detail {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .anime-list-Detail {
    width: 32%;
    padding: 1px;
    border-radius: 0px;
    margin-top: 5px;
    border: #fff;
    box-shadow: none;
  }

  .card-Detail img {
    border-radius: 3px 0px 0px 3px;
    height: 300px;
  }
  .card-rec {
    width: 100%;
  }

  .synopsis-div {
    border: 0.2px solid;
    background-color: aliceblue;
    height: auto;
    border-radius: 5px;
    margin-bottom: 7%;

    width: 96%;
    margin-left: 6px;
  }
  .synopsis {
    font-size: 10px;
    justify-content: inherit;
    display: none;
  }
  .synopsis2 {
    font-size: 10px;
    justify-content: inherit;
    display: inline;
    margin-left: 2px;
  }

  .synopsiss {
    font-size: 10px;
    font-weight: bold;

    color: #4e4249;
  }

  .anime-recomendation {
    margin-left: 4px;
    width: 50%;
    margin-bottom: 0px;
    padding: 1px;
    border-radius: 0px;
    margin-top: 5px;
    box-shadow: none;
  }
  .image-rec {
    border-radius: 3px 0px 0px 3px;
    height: 160px;
    width: auto;
    margin-bottom: 0px;
  }
  .title-recommendation {
    display: flex;
    height: 42px;

    align-items: center;
    text-align: center;
  }
  .title-rec {
    font-size: 14px;
    font-weight: bold;
  }
}

@media screen and (max-width: 500px) {
  .nav-container {
    padding: 10px;
    display: flex;
  }
  .logo img {
    width: 60px;
    height: 28px;
    flex: 1;
  }
  .nav-element {
    margin-bottom: 10px;
    text-align: center;
    flex: 1;
  }
  .nav-element span {
    font-size: 52%;
    margin-right: 5px;
  }

  .nav-element2 {
    display: none;
  }

  .card-Detail {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .anime-list-Detail {
    width: 32%;
    padding: 1px;
    border-radius: 0px;
    margin-top: 5px;
    border: #fff;
    box-shadow: none;
  }

  .card-Detail img {
    border-radius: 3px 0px 0px 3px;
    height: 160px;
  }
  .card-rec {
    width: 100%;
  }

  .synopsis-div {
    border: 0.2px solid;
    background-color: aliceblue;
    height: auto;
    border-radius: 5px;
    margin-bottom: 7%;

    width: 96%;
    margin-left: 6px;
  }
  .synopsis {
    font-size: 10px;
    justify-content: inherit;
    display: none;
  }
  .synopsis2 {
    font-size: 11px;
    justify-content: inherit;
    display: inline;
    margin-left: 2px;
  }

  .synopsiss {
    font-size: 10px;
    font-weight: bold;

    color: #4e4249;
  }

  .anime-recomendation {
    margin-left: 4px;
    width: 50%;
    margin-bottom: 0px;
    padding: 1px;
    border-radius: 0px;
    margin-top: 5px;
    box-shadow: none;
  }
  .image-rec {
    border-radius: 3px 0px 0px 3px;
    height: 160px;
    width: auto;
    margin-bottom: 0px;
  }
  .title-recommendation {
    display: flex;
    height: 42px;

    align-items: center;
    text-align: center;
  }
  .title-rec {
    font-size: 14px;
    font-weight: bold;
  }
}
