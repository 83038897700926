body {
  margin: 0;
  padding: 0;
}

.nav-container {
  background-color: #dd1d1d;
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: rgb(6 1 59);
  color: #fff;
  margin-top: 0px;
}

.logo img {
  height: 50px;
  width: 120px;
  flex: 0.3;
}

.nav-element {
  justify-content: space-between;
  font-size: 20px;
  flex: 0.5;
  text-align: center;
  font-weight: 300;
}
.nav-element span {
  display: inline;
  margin-right: 30px;
}

.nav-element span:hover {
  color: rgb(0, 0, 0);
  text-decoration: underline;
}

.nav-element2 {
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  flex: 0.5;
  text-align: end;
  font-weight: 400;
}

.nav-element2 span {
  margin-right: 20px;
}
.nav-element2 span:hover {
  color: black;
  text-decoration: underline;
}
.all {
  border-color: black;
}

.search {
  font-size: large;
  border: none;
}
.search-input {
  border: none;
  border-radius: 7px;
  color: rgb(4, 59, 61);
  flex: 0.2;
}
.search-input::placeholder {
  font-size: medium;
  margin-left: 10px;
}

.main {
  background-color: rgba(131, 129, 127, 0.571);
  justify-content: center;
  align-items: center;
  display: flex;
}
.anime-list {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  width: 15%;
  margin-right: 2px;
}

.anime-list:hover {
  transform: scale(0.9);

  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
}

.card {
  display: flex;
  flex-wrap: wrap;
}
.card img {
  width: 100%;
  height: 330px;
  border-radius: 5px;
}

.card .title {
  font-size: 16px;
  font-weight: bold;
  flex: 1;
}
.card .durration {
  font-size: 14px;
  color: #888;
}
.status {
  font-size: 14px;
  color: #888;
  flex: 1;
  text-align: end;
}
.title-status {
  display: flex;
  align-items: center;
}


@media screen and (max-width: 1000px) {
  .nav-container {
    padding: 10px;
    display: flex;
  }
  .logo img {
    width: 62px;
    height: 30px;
    flex: 1;
  }
  .nav-element {
    margin-bottom: 10px;
    text-align: center;
    flex: 1;
  }
  .nav-element span {
    font-size: 67%;
    margin-right: 5px;
  }

  .nav-element2 {
    flex: 1;
  }

  .search-input {
    width: 50%;
  }

  .anime-list {
    width: 19%;
    padding: 1px;
    border-radius: 0px;
    margin-top: 5px;
    border: #fff;
    box-shadow: none;
  }

  .card {
    width: auto;
    justify-content: center;
  }
  .card img {
    border-radius: 3px 0px 0px 3px;
    height: 160px;
  }

  .card .title {
    font-size: 15px;
    text-align: center;
  }

  .card .duration {
    font-size: 12px;
  }

  .status {
    display: none;
  }
  .title-status {
    height: 30px;
  }
}

@media screen and (max-width: 700px) {
  .nav-container {
    padding: 10px;
    display: flex;
  }
  .logo img {
    width: 62px;
    height: 30px;
    flex: 1;
  }
  .nav-element {
    margin-bottom: 10px;
    text-align: center;
    flex: 1;
  }
  .nav-element span {
    font-size: 90%;
    margin-right: 5px;
  }

  .nav-element2 {
    display: none;
  }
  .search-input::placeholder {
    font-size: small;
    margin-left: 10px;
  }

  .anime-list {
    width: 32%;
    padding: 1px;
    border-radius: 0px;
    margin-top: 5px;
    border: #fff;
    box-shadow: none;
  }

  .card {
    width: auto;
    justify-content: center;
  }
  .card img {
    border-radius: 3px 0px 0px 3px;
    height: 160px;
  }

  .card .title {
    font-size: 15px;
    text-align: center;
  }

  .card .duration {
    font-size: 12px;
  }

  .status {
    display: none;
  }
  .title-status {
    height: 30px;
  }
  .search-input {
    flex: 0.4;
  }
}

@media screen and (max-width: 500px) {
  .nav-container {
    padding: 10px;
    display: flex;
  }
  .logo img {
    width: 60px;
    height: 28px;
    flex: 1;
  }
  .nav-element {
    margin-bottom: 10px;
    text-align: center;
    flex: 1.5;
  }
  .nav-element span {
    font-size: 52%;
    margin-right: 5px;
  }
  .search-input::placeholder {
    font-size: xx-small;
    margin-left: 10px;
  }
  .nav-element2 span {
    display: none;
  }

  .search {
    flex: 1;
    margin-top: 10px;
  }

  .search-input {
    flex: 0.3;
    height: 14px;
  }

  .anime-list {
    width: 32%;
    padding: 1px;
    border-radius: 0px;
    margin-top: 5px;
    border: #fff;
    box-shadow: none;
  }

  .card {
    width: auto;
    justify-content: center;
  }
  .card img {
    border-radius: 3px 0px 0px 3px;
    height: 160px;
  }

  .card .title {
    font-size: 15px;
    text-align: center;
  }

  .card .duration {
    font-size: 12px;
  }

  .status {
    display: none;
  }
  .title-status {
    height: 30px;
  }
}
